// TODO: should try to use the same schema in dashboard product create! currently duplicate logic

const {
  FORETASTE_STATUS,
  CONTACT_FORM_STATUS,
  LOGISTICE_TYPE,
} = require('./Constants');

function oid2id(instance) {
  let result = {
    ...instance,
    id: instance._id ? instance._id.$oid : instance.id,
  };
  delete result._id;
  return result;
}

function id2oid(instance) {
  // not in use
  // FIXME
  return instance;
}

function product(p) {
  const _sortingAllowLogistic = (a, b) => {
    const _sortingPriority = [
      LOGISTICE_TYPE.selfPick,
      LOGISTICE_TYPE.icatNormal,
      LOGISTICE_TYPE.icatFridge,
      LOGISTICE_TYPE.icatFreeze,
    ];
    return _sortingPriority.indexOf(a) - _sortingPriority.indexOf(b);
  };

  return {
    ...p,
    images: p.images || [],
    spec: p.spec || [],
    available_times_home: p.available_times_home || [],
    available_times_self_pick: p.available_times_self_pick || [],
    category: p.category || [],
    product_list: p.product_list || [],
    recommend_additional_products: p.recommend_additional_products || [],
    allow_logistic_type: (p.allow_logistic_type || [])
      .slice()
      .sort(_sortingAllowLogistic),
  };
}

function cart(c) {
  return {
    ...c,
    items: c.items.map((item) => ({...item, product: oid2id(item.product)})),
    extra_items: c.extra_items || [],
  };
}

function order(o) {
  return {
    ...o,
    delivery_date: o.delivery_date || {$date: ''},
    extra_items: o.extra_items || [],
  };
}

function preOrder(o) {
  return {
    ...o,
    delivery_date: o.delivery_date || {$date: ''},
    extra_items: o.extra_items || [],
  };
}

function foretaste(f) {
  return {
    ...f,
    status: f.status || FORETASTE_STATUS.pending,
  };
}

function contactUs(c) {
  return {
    ...c,
    status: c.status || CONTACT_FORM_STATUS.pending,
  };
}

module.exports = {
  id2oid,
  oid2id,
  product,
  cart,
  order,
  preOrder,
  foretaste,
  contactUs,
};
