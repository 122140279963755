import {getOutlet} from 'reconnect.js';
import {navigate as _navigate} from 'gatsby';
import Config from '../data.json';

const LoadingOutlet = getOutlet('loading');
const ModalOutlet = getOutlet('modal');

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading) {
  setTimeout(() => {
    LoadingOutlet.update(loading);
  }, 0);
}

function setModal(modal) {
  setTimeout(() => {
    ModalOutlet.update(modal);
  }, 0);
}

function navigate(nextRoute, options = {loading: false}) {
  console.log('navigate to', nextRoute);
  _navigate(nextRoute);
}

async function fetchCustomResources() {
  return null;
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  console.log(formData);
  return false;
}

const UserOutlet = getOutlet('user');

async function githubRebuild() {
  return fetch(
    `${Config.apiHost}/misc/github/rebuild?token=${
      UserOutlet.getValue().token
    }&branch=${Config.branch}&stage=${Config.stage}`,
  );
}

export {
  delay,
  setLoading,
  setModal,
  navigate,
  fetchCustomResources,
  onAdminFormSubmit,
  githubRebuild,
};
