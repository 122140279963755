import {navigate} from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';

import * as AppContext from '../AppContext';
import useClickOutside from '../hooks/use-click-outside';
import imgIconFb from '../images/icon-menu-facebook.png';
import imgIconIg from '../images/icon-menu-instagram.png';
import imgLogo from '../images/logo.png';
import URL_PATH from '../UrlPath';

function NavbarMenu(props) {
  const app = React.useContext(AppContext.Context);
  const isMenuOpen = app.isMenuOpen;
  const ref = React.useRef();
  const _onClickOutside = () => {
    isMenuOpen && app.actions.setOpenMenu(false);
  };
  const {isClickOutside} = useClickOutside(ref.current, _onClickOutside);

  const MENU_ITEMS = [
    {
      display: '首頁',
      path: URL_PATH.landing,
    },
    {
      display: '關於法朋',
      path: URL_PATH.about,
    },
    {
      display: '最新消息',
      path: URL_PATH.newsList,
    },
    {
      display: '商品列表',
      path: URL_PATH.productList,
    },
    {
      display: '喜餅與彌月',
      path: URL_PATH.foretaste,
    },
    {
      display: '常見問題',
      path: URL_PATH.faq,
    },
    {
      display: '商業合作',
      path: `${URL_PATH.landing}#contact`,
    },
    {
      display: app.profile ? '會員中心' : '註冊/登入',
      path: URL_PATH.profile,
    },
  ];

  return (
    <Wrapper ref={ref}>
      <div className="logo">
        <img src={imgLogo} height={50} alt="logo" />
      </div>

      <div className="items">
        {MENU_ITEMS.map((item, idx) => (
          <div
            key={idx}
            className="item"
            onClick={() => {
              navigate(item.path);
              app.actions.setOpenMenu(!isMenuOpen);
            }}>
            {item.display}
          </div>
        ))}
      </div>

      <div className="bottom">
        <a
          className="social-icon-btn"
          href={'https://www.instagram.com/lerubanpatisserie/?hl=zh-tw'}
          target="_blank">
          <img src={imgIconIg} />
        </a>
        <a
          className="social-icon-btn"
          href={'https://www.facebook.com/LeRubanPatisserie'}
          target="_blank">
          <img src={imgIconFb} />
        </a>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  color: #595757;
  width: 100%;
  height: 100%;
  border-left: 1px solid #e5e5ea;
  padding: 20px 0px 30px 35px;

  display: flex;
  flex-direction: column;

  .logo {
    margin-bottom: 60px;
  }

  .items {
    flex: 1;
    & > .item {
      cursor: pointer;
      font-size: 14px;
      color: #595757;
      letter-spacing: 0;
      text-align: left;
      padding: 10px 0px;
      margin-bottom: 5px;
    }
  }

  .bottom {
    display: flex;

    & > .social-icon-btn {
      cursor: pointer;
      border: 1px solid #e5e5ea;
      border-radius: 50%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      margin-right: 15px;

      & > img {
        width: 100%;
      }
    }
  }
`;

export default NavbarMenu;
