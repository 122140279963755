import {navigate} from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../AppContext';
import imgLogo from '../images/logo.png';
import imgIconMenu from '../images/icon-menu.png';
import imgIconProfile from '../images/icon-profile.png';
import imgIconBag from '../images/icon-bag.png';
import URL_PATH from '../UrlPath';
import {BREAK_POINTS} from '../domain/Constants';
import Button from './Button';

const MENU_ITEMS = [
  {
    display: '關於法朋',
    path: URL_PATH.about,
  },
  {
    display: '最新消息',
    path: URL_PATH.newsList,
  },
  {
    display: '商品列表',
    path: URL_PATH.productList,
  },
  {
    display: '喜餅與彌月',
    path: URL_PATH.foretaste,
  },
  {
    display: '常見問題',
    path: URL_PATH.faq,
  },
  {
    display: '商業合作',
    path: `${URL_PATH.landing}#contact`,
  },
];

const Navbar = (props) => {
  const app = React.useContext(AppContext.Context);
  const isMenuOpen = app.isMenuOpen;

  const _onProfileButtonClick = React.useCallback(() => {
    navigate(URL_PATH.profile);
  }, []);

  const _badgeCount = React.useMemo(() => {
    return app.cart ? app.cart.items.length : 0;
  }, [app.cart]);

  return (
    <Wrapper>
      <div className="left group-actions">
        <div className="button" onClick={() => navigate(URL_PATH.landing)}>
          <img src={imgLogo} width={131} height={50} />
        </div>
      </div>
      <div className="right group-actions">
        {MENU_ITEMS.map((item, idx) => (
          <div
            key={idx}
            className="button secondary"
            onClick={() => navigate(item.path)}>
            {item.display}
          </div>
        ))}
        <div className="button" onClick={() => navigate(URL_PATH.cart)}>
          <img src={imgIconBag} width={24} height={24} />
          {_badgeCount > 0 && <div className="badge">{_badgeCount}</div>}
        </div>
        <div className="button secondary">
          <Button
            style={{width: 120}}
            size="small"
            onClick={_onProfileButtonClick}>
            {app.profile ? '會員中心' : '註冊/登入'}
          </Button>
        </div>
        <div
          className="button"
          onClick={() => app.actions.setOpenMenu(!isMenuOpen)}>
          <img src={imgIconMenu} width={24} height={24} />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  max-width: var(--content-max-width);
  background-color: #fff;
  margin: 0 auto;
  padding: 0px var(--navbar-padding);

  display: flex;
  justify-content: space-between;

  & > .group-actions {
    display: flex;
    align-items: center;
    & .button {
      cursor: pointer;
      margin-right: 30px;
      position: relative;
      font-family: 'Noto Sans TC';
      font-size: 16px;

      & .badge {
        background-color: var(--theme-primary);
        width: 16px;
        height: 16px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5px;
        top: -1px;
        font-size: 12px;
        color: #fff;
        border-radius: 50%;
      }
    }

    & .button:last-child {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .group-actions {
      & .button.secondary {
        display: none;
      }
    }
  }
`;

export default Navbar;
