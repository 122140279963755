import React from 'react';
import './App';
import {navigate} from 'gatsby';
import * as AppContext from './AppContext';
import * as AppActions from './AppActions';
import Layout from './components/Layout';
import URL_PATH from './UrlPath';
import {isDashboardPath, isLoginDashboardPath} from './domain/Url';
import queryParams from './utils/queryParams';
import Gtag from './utils/gtag';
import qs from 'query-string';

const _handleInitialRouting = (pathname, profile) => {
  let replaceRoute = null;
  if (pathname.indexOf(URL_PATH.dashboardHome) === 0 && !profile) {
    replaceRoute = URL_PATH.dashboardLogin;
  } else if (pathname.indexOf(URL_PATH.dashboardLogin) === 0 && profile) {
    replaceRoute = URL_PATH.dashboardHome;
  } else if (pathname.indexOf(URL_PATH.register) === 0 && profile) {
    replaceRoute = URL_PATH.profile;
  } else if (pathname.indexOf(URL_PATH.order) === 0 && !profile) {
    replaceRoute = URL_PATH.profile;
  } else if (pathname.indexOf(URL_PATH.orderList) === 0 && !profile) {
    replaceRoute = URL_PATH.profile;
  } else if (pathname.indexOf(URL_PATH.cart) === 0 && !profile) {
    replaceRoute = URL_PATH.profile;
  }

  return replaceRoute;
};

export function PageContainer(props) {
  const app = React.useContext(AppContext.Context);
  const {location} = props;
  const {pathname, search} = location;

  let profile = null;
  let _dataInUrl = queryParams(search);
  let _tokenInUrl = _dataInUrl.token;
  let _refreshTokenInUrl = _dataInUrl.refresh;
  let _is_registered = _dataInUrl.is_registered || 'false';

  React.useEffect(() => {
    const _fetch = async () => {
      // do autoLogin and app initialization here
      if (!app.initialized) {
        AppActions.setLoading(true);

        try {
          let _isDashboard = isDashboardPath(props.location.pathname);
          let _isLoginDashboard = isLoginDashboardPath(props.location.pathname);
          let _refreshToken = null;
          let _token = null;
          if (_isDashboard || _isLoginDashboard) {
            _refreshToken = window.localStorage.getItem('staff-refresh-token');
            _refreshToken &&
              (_token = await app.actions.refreshToken(_refreshToken, true));
            _token && (profile = await app.actions.autoStaffLogin(_token));
          } else {
            // ec site
            if (_tokenInUrl) {
              _token = _tokenInUrl;
            }
            if (_refreshTokenInUrl) {
              window.localStorage.setItem('refresh-token', _refreshTokenInUrl);
            }

            if (!_token) {
              _refreshToken = window.localStorage.getItem('refresh-token');
              _refreshToken &&
                (_token = await app.actions.refreshToken(_refreshToken));
            }

            _token && (profile = await app.actions.autoLogin(_token));
          }
        } catch (err) {
          // auto login failed
        }

        if (_is_registered === 'true') {
          Gtag('event', 'sign_up', {
            method: profile.provider,
          });
          const params = qs.parse(window.location.search);
          delete params.is_registered;
          await navigate(
            `${window.location.pathname}?${qs.stringify(params)}`,
            {
              replace: true,
            },
          );
        }

        // set data into appContext
        try {
          app.actions.setProducts(await app.actions.getFileProducts());
          app.actions.setCategories(await app.actions.getFileCategories());
        } catch (err) {
          app.actions.setProducts([]);
          app.actions.setCategories(null);
        }

        const replaceRoute = _handleInitialRouting(pathname, profile);
        if (replaceRoute) {
          navigate(replaceRoute, {replace: true});
        }

        app.actions.setInitialized(true);
        AppActions.setLoading(false);
      }
    };

    _fetch();
  }, [app.initialized, app.actions]);

  return <Layout {...props}>{props.children}</Layout>;
}

export function AppRoot(props) {
  return <AppContext.Provider {...props}>{props.children}</AppContext.Provider>;
}
