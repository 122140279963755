import React from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';
import URL_PATH from '../UrlPath';
import {navigate} from 'gatsby';
import imgLogo from '../images/logo.png';
import {ArrowRightOutlined} from '@ant-design/icons';
import dataJson from '../../data.json';

const _EC_ROUTE_ITEMS = [
  {
    key: 'home',
    title: '總覽 Home',
    path: URL_PATH.dashboardHome,
  },
  {
    key: 'config-ec',
    title: '前台網站編輯 ConfigEc',
    path: URL_PATH.dashboardConfigEc,
  },
  {
    key: 'products',
    title: '產品 Products',
    path: URL_PATH.dashboardProducts,
  },
  {
    key: 'categories',
    title: '商品類別 Categories',
    path: URL_PATH.dashboardCategories,
  },
  {
    key: 'orders',
    title: '訂單 Orders',
    path: URL_PATH.dashboardOrders,
  },
  {
    key: 'foretastes',
    title: '試吃表單 Foretastes',
    path: URL_PATH.dashboardForetastes,
  },
  {
    key: 'contact-us',
    title: '聯絡我們表單 Contact Us',
    path: URL_PATH.dashboardContactUs,
  },
  {
    key: 'stock-preview',
    title: '庫存預覽 Stock',
    path: URL_PATH.dashboardStockPreview,
  },
  {
    key: 'news',
    title: '最新消息 News',
    path: URL_PATH.dashboardNews,
  },
  {
    key: 'memebers',
    title: '會員 Members',
    path: URL_PATH.dashboardMembers,
  },
  {
    key: 'staffs',
    title: '後台人員 Staffs',
    path: URL_PATH.dashboardStaffs,
  },
  {
    key: 'settings',
    title: '設定 Settings',
    path: URL_PATH.dashboardSettings,
  },

  // {
  //   icon: <ArrowRightOutlined />,
  //   key: 'go-bulk-site',
  //   title: '前往大宗 Bulk',
  //   path: URL_PATH.dashboardBulkHome,
  // },
];

if (dataJson.enableCoupon) {
  _EC_ROUTE_ITEMS.push({
    key: 'coupons',
    title: '優惠券 Coupons',
    path: URL_PATH.dashboardCoupon,
  });
}

const _BULK_ROUTE_ITEMS = [
  {
    key: 'bulk-home',
    title: '大宗總覽 Bulk Home',
    path: URL_PATH.dashboardBulkHome,
  },
  {
    key: 'bulk-orders',
    title: '大宗訂單 Bulk Orders',
    path: URL_PATH.dashboardBulkOrderList,
  },
  {
    icon: <ArrowRightOutlined />,
    key: 'go-ec-site',
    title: '前往EC EC',
    path: URL_PATH.dashboardHome,
  },
];

function DashboardSider(props) {
  const {collapsed, onCollapse, width, collapsedWidth, location} = props;
  const [activeRoute, setActiveRoute] = React.useState(null);
  const ACTIVE_ROUTE_ITEMS = React.useMemo(() => {
    return /\/bulk(\/|$)/.test(location.pathname)
      ? _BULK_ROUTE_ITEMS
      : _EC_ROUTE_ITEMS;
  }, [location.pathname]);

  React.useEffect(() => {
    const _triggerredItem = ACTIVE_ROUTE_ITEMS.find(
      (it) => it.path === location.pathname,
    );
    if (_triggerredItem) {
      setActiveRoute(_triggerredItem.key);
    }
  }, [location.pathname]);

  const _onItemClick = React.useCallback(
    ({item, key, keyPath, domEvent}) => {
      const _triggerredItem = ACTIVE_ROUTE_ITEMS.find((it) => it.key === key);
      navigate(_triggerredItem.path);
    },
    [ACTIVE_ROUTE_ITEMS],
  );

  return (
    <Wrapper>
      <Ant.Layout.Sider
        style={{height: '100%'}}
        collapsible
        width={width}
        className={'rev-antd-sider'}
        collapsedWidth={collapsedWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}>
        <div className="sider-header">
          <img className="logo" src={imgLogo} width={collapsed ? 50 : 150} />
        </div>
        <Ant.Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeRoute]}
          onClick={_onItemClick}>
          {ACTIVE_ROUTE_ITEMS.map((item) => (
            <Ant.Menu.Item key={item.key} icon={item.icon || null}>
              {item.title}
            </Ant.Menu.Item>
          ))}
        </Ant.Menu>
      </Ant.Layout.Sider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  & > .rev-antd-sider {
    & div.sider-header {
      padding: 50px 0px 30px;
      display: flex;
      justify-content: center;
    }

    & .ant-layout-sider-children {
      overflow-y: auto;
    }
  }
`;

export default DashboardSider;
