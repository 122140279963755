const BREAK_POINTS = {
  // desktop first, included. ex, 1300 -> desktop | 768 -> tablet
  desktop: 1400,
  tablet: 768,
  mobile: 600,
};

const INVOICE_TYPE = {
  twoCopies: 'two_copies',
  threeCopies: 'three_copies',
};

const INVOICE_TYPE_DISPLAY = {
  two_copies: '二聯式',
  three_copies: '三聯式',
};

const INVOICE_SUBTYPE = {
  memberCarrier: 'EG0142',
  mobileCarrier: '3J0002', // mapping with value to 3rd party
  citizenCarrier: 'CQ0001',
};

const INVOICE_SUBTYPE_DISPLAY = {
  EG0142: '會員載具',
  '3J0002': '手機條碼載具',
  CQ0001: '自然人憑證條碼',
};

const INVOICE_STATUS = {
  waiting: 'waiting',
  issued: 'issued',
  issueFail: 'issue_fail',
  voided: 'voided',
  voidFail: 'void_fail',
  generated: 'generated',
  generate_fail: 'generate_fail',
};

const INVOICE_STATUS_DISPLAY = {
  waiting: '尚未開立',
  issued: '已開立',
  issue_fail: '開立失敗',
  voided: '已作廢',
  void_fail: '作廢失敗',
  generated: '代傳成功',
  generate_fail: '代傳失敗',
};

const LOGISTICE_TYPE = {
  selfPick: 'self_pick',
  icatFreeze: 'icat_freeze',
  icatFridge: 'icat_fridge',
  icatNormal: 'icat_normal',
};

const LOGISTICE_TYPE_DISPLAY = {
  self_pick: '自取',
  icat_freeze: '冷凍宅配',
  icat_fridge: '冷藏宅配',
  icat_normal: '常溫宅配',
};

const LOGISTICE_TYPE_CATEGORY = {
  selfPick: 'self_pick',
  home: 'icat',
};

const LOGISTICE_TYPE_CATEGORY_DISPLAY = {
  self_pick: '自取',
  icat: '宅配',
};

const PAYMENT_STATUS = {
  success: 'success',
  failure: 'failure',
  waiting: 'waiting',
};

const PAYMENT_STATUS_DISPLAY = {
  success: '線上信用卡已付款',
  failure: '付款失敗',
  waiting: '付款異常',
};

const ORDER_STATUS = {
  done: 'done',
  waiting: 'waiting',
  cancelled: 'cancelled',
};

const ORDER_UI_STATUS = {
  doneSelfPick: 'doneSelfPick',
  doneIcat: 'doneIcat',
  waiting: 'waiting',
  cancelled: 'cancelled',
};

const ORDER_UI_STATUS_DISPLAY = {
  doneSelfPick: '已自取', // sub display for better ui hint, actual data still save as value "done"
  doneIcat: '已出貨', // sub display for better ui hint, actual data still save as value "done"
  waiting: '等待配送',
  cancelled: '訂單已取消',
};

const ORDER_STATUS_DISPLAY = {
  done: '已完成',
  waiting: '等待配送',
  cancelled: '訂單已取消',
};

const PRODUCT_TYPE = {
  default: 'default',
  additional: 'additional',
  enterprise: 'enterprise',
};

const FORETASTE_TYPE = {
  brideCake: 'brideCake',
  fullMonthCake: 'fullMonthCake',
};

const FORETASTE_TYPE_DISPLAY = {
  brideCake: '喜餅',
  fullMonthCake: '彌月',
};

const FORETASTE_STATUS = {
  pending: 'pending',
  contact: 'contact',
  cancelled: 'cancelled',
  shipped: 'shipped',
};

const FORETASTE_STATUS_DISPLAY = {
  pending: '未處理',
  contact: '準備中',
  cancelled: '已取消',
  shipped: '已寄出',
};

const CONTACT_FORM_STATUS = {
  pending: 'pending',
  contact: 'contact',
  cancelled: 'cancelled',
  done: 'done',
};

const CONTACT_FORM_STATUS_DISPLAY = {
  pending: '未處理',
  contact: '聯絡中',
  cancelled: '已取消',
  done: '已完成',
};

const CUSTOM_CARD_LANG = {
  zh: 'zh',
  en: 'en',
};

const CATEGORY_ALL_PRODUCTS_KEY = 'all';

const ADDITIONAL_TABLEWARE_PRICE = 10; // FIXME: legacy structure, not in use

const INVALID_CART_ERROR_KEYS = [
  // FIXME: refactor for `INVALID_CART_ERROR_KEYS`, move it to `INVALID_CART_DATA_ERROR_KEYS`
  'item_expired',
  'calendar_not_found',
  'checkout_without_item_error',
  'additional_stock_not_enough',
  'stock_not_enough',
  'product_not_found', // spec not found
  'stock_not_found', // for general unknow error
  'invalid_box_spec', // box_spec is invalid
  'item_not_published', // product is not published
  'invalid_entp_purchase_quantity', // entp product purchase quantity invalid
  'invalid_entp_purchase_time', // entp product purchase time invalid
  'invalid_item_error', // general error //
];

const INVALID_CART_DATA_ERROR_KEYS = {
  invalid_delivery_address: {
    // receiver_address is not allowed, ex: 離島地區
    hint: '寄件地址目前不提供宅配服務！請調整您的到貨地址！',
  },
};

const INVALID_RECEIVER_ADDRESS_CITY = [
  '釣魚台',
  '南海島',
  '連江縣',
  '金門縣',
  '澎湖縣',
];

const PROVIDER = {
  default: 'default',
  google: 'google',
  facebook: 'facebook',
};

const PROVIDER_DISPLAY = {
  [PROVIDER.default]: {
    value: PROVIDER.default,
    label: '一般',
  },
  [PROVIDER.google]: {
    value: PROVIDER.google,
    label: 'google',
  },
  [PROVIDER.facebook]: {
    value: PROVIDER.facebook,
    label: 'facebook',
  },
};

const HAS_EXTRA_DELIVERY_FEE_FOR_RECEIVER_ADDRES_CITY = ['花蓮縣', '台東縣']; // not in use, just a note

const LINE_FRIEND_LINK = 'https://line.me/R/ti/p/@351buhar';

module.exports = {
  BREAK_POINTS,
  INVOICE_TYPE,
  INVOICE_TYPE_DISPLAY,
  INVOICE_SUBTYPE,
  INVOICE_SUBTYPE_DISPLAY,
  INVOICE_STATUS,
  INVOICE_STATUS_DISPLAY,
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_CATEGORY,
  LOGISTICE_TYPE_CATEGORY_DISPLAY,
  LOGISTICE_TYPE_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
  ORDER_UI_STATUS,
  ORDER_UI_STATUS_DISPLAY,
  PRODUCT_TYPE,
  FORETASTE_TYPE,
  FORETASTE_TYPE_DISPLAY,
  FORETASTE_STATUS,
  FORETASTE_STATUS_DISPLAY,
  CONTACT_FORM_STATUS,
  CONTACT_FORM_STATUS_DISPLAY,
  CUSTOM_CARD_LANG,
  ADDITIONAL_TABLEWARE_PRICE,
  CATEGORY_ALL_PRODUCTS_KEY,
  INVALID_CART_ERROR_KEYS,
  INVALID_CART_DATA_ERROR_KEYS,
  INVALID_RECEIVER_ADDRESS_CITY,
  LINE_FRIEND_LINK,
  PROVIDER_DISPLAY,
  PROVIDER,
};
