import {css} from 'styled-components';

const themeBackgroundColor = '#F6F6F6';
const themePrimary = '#B79D7A';
const themeLighter = '#d8bb94';
const red = '#ff4d4f';
const red2 = '#ffccc7';
const gold = '#faad14';
const gold2 = '#fff1b8';

const ThemeCssVariables = css`
  --theme-background: ${themeBackgroundColor};
  --theme-primary: ${themePrimary};
  --theme-lighter: ${themeLighter};
  --color-red: ${red};
  --color-red-2: ${red2};
  --color-glod: ${gold};
  --color-glod-2: ${gold2};
`;

export {ThemeCssVariables, themePrimary, themeLighter, themeBackgroundColor};
