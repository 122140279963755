import React, {useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as Ant from 'antd';
import '../normalize.css';
import 'antd/dist/antd.less';
import '../index.css';
import Navbar from './Navbar';
import NavbarMenu from './NavbarMenu';
import DashboardSider from './DashboardSider';
import URL_PATH from '../UrlPath';
import {ThemeCssVariables} from '../domain/Theme';
import * as AppContext from '../AppContext';
import Footer from './Footer';
import {BREAK_POINTS} from '../domain/Constants';
import {isBlankLayoutPath, isDashboardPath} from '../domain/Url';
import * as Config from '../../data.json';

const LayoutConfig = {
  navbar: {
    height: 90,
    padding: 80,
    smallerPadding: 20,
  },
  menu: {
    width: 300,
  },
  content: {
    maxWidth: 1240,
    padding: 20,
    smallerPadding: 20,
  },
  sider: {
    width: 200,
    collapsedWidth: 80,
  },
};

const DEFAULT_SEO = {
  title:
    'Le Ruban Pâtisserie 法朋烘焙甜點坊 | 一個社區裡，只要有一家甜點店，那個社區就會幸福。',
  image: 'https://www.revtel.tech/images/og-lr.jpg',
  description:
    '法朋重視的是能夠一同分享的溫暖感受，結合生活土地上的元素，使用當季新鮮的材料，於傳統中解構更新，呈現鮮明的季節時令感，創造一個能夠滿足現代人們對於甜品的需求與想像的甜點城堡。',
  keywords: '法朋,烘焙甜點,蛋糕',

  url: 'https://lerubanpatisserie.com/',
  type: 'website',
};

function Layout(props) {
  const [siderCollapsed, setSiderCollapsed] = React.useState(false);
  const app = React.useContext(AppContext.Context);
  const isMenuOpen = React.useMemo(() => app.isMenuOpen, [app.isMenuOpen]);
  const {children, location} = props;

  useEffect(() => {
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`;
    document.head.appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', Config.gaId, {debug_mode: true});
  }, []);

  const isBlankLayout = React.useMemo(() => {
    return isBlankLayoutPath(location.pathname);
  }, [location.pathname]);

  const isDashboard = React.useMemo(() => {
    return isDashboardPath(location.pathname);
  }, [location.pathname]);

  let seo = {
    ...DEFAULT_SEO,
  };

  return (
    <Wrapper siderCollapsed={siderCollapsed}>
      <Helmet>
        <title>{seo.title}</title>
        <link rel="canonical" href="../images/favicon.png" />

        {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.image && <meta name="image" content={seo.image} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}

        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.type && <meta property="og:type" content={seo.type} />}

        <meta name="author" content="RevtelTech 忻旅科技股份有限公司" />
      </Helmet>

      <Ant.Layout className={`app-document`} style={{minHeight: '100vh'}}>
        {!isBlankLayout &&
          (isDashboard ? (
            <div className="sider-wrapper">
              <DashboardSider
                location={props.location}
                collapsed={siderCollapsed}
                onCollapse={setSiderCollapsed}
                width={LayoutConfig.sider.width}
                collapsedWidth={LayoutConfig.sider.collapsedWidth}
              />
            </div>
          ) : (
            <div className={`navbar-wrapper${isMenuOpen ? ' menu-open' : ''}`}>
              <Navbar location={props.location} />
            </div>
          ))}

        <Ant.Layout.Content
          className={`layout-content${
            isDashboard && !isBlankLayout ? ' dashboard ' : ''
          }${isMenuOpen ? ' menu-open' : ''}`}>
          {children}
        </Ant.Layout.Content>

        {isDashboard || isBlankLayout ? null : (
          <div className={`footer-wrapper${isMenuOpen ? ' menu-open' : ''}`}>
            <Footer />
          </div>
        )}
      </Ant.Layout>

      <div className={`menu-wrapper${isMenuOpen ? ' menu-open' : ''}`}>
        <NavbarMenu />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${ThemeCssVariables}
  --navbar-height: ${LayoutConfig.navbar.height}px;
  --navbar-padding: ${LayoutConfig.navbar.padding}px;
  --sider-width: ${LayoutConfig.sider.width}px;
  --sider-collapsed-width: ${LayoutConfig.sider.collapsedWidth}px;
  --menu-width: ${LayoutConfig.menu.width}px;
  --content-max-width: ${LayoutConfig.content.maxWidth}px;
  --content-padding: ${LayoutConfig.content.padding}px;

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    --navbar-padding: ${LayoutConfig.navbar.smallerPadding}px;
    --content-padding: ${LayoutConfig.content.smallerPadding}px;
  }

  & > .menu-wrapper {
    position: fixed;
    width: var(--menu-width);
    height: 100vh;
    top: 0px;
    right: calc(-1 * var(--menu-width));
    transition: right 0.08s ease-in;
  }

  & > .menu-wrapper.menu-open {
    right: 0px;
  }

  & > .app-document {
    position: relative;
  }

  & .navbar-wrapper {
    height: var(--navbar-height);
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: #fff;
  }

  & .footer-wrapper {
    width: 100%;
    position: relative;
  }

  & .navbar-wrapper,
  & .footer-wrapper {
    left: 0px;
    transition: left 0.08s ease-in;
  }

  & .navbar-wrapper.menu-open,
  & .footer-wrapper.menu-open {
    left: calc(-1 * var(--menu-width));
  }

  & .sider-wrapper {
    position: fixed;
    height: 100vh;
  }

  & .layout-content {
    background-color: var(--theme-background);
    transition: padding 0.2s ease;
    display: flex;
    flex-direction: column;

    &:not(&.dashboard) {
      padding-top: var(--navbar-height);
      position: relative;
      left: 0px;
      transition: left 0.08s ease-in;
    }

    &:not(&.dashboard).menu-open {
      position: relative;
      left: calc(-1 * var(--menu-width));
    }

    &.dashboard {
      padding-left: ${(props) =>
        props.siderCollapsed
          ? 'var(--sider-collapsed-width)'
          : 'var(--sider-width)'};
    }
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
