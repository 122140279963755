import React from 'react';

function useClickOutside(domEle, onClickOutside) {
  const [isClickOutside, setIsClickOutside] = React.useState(false);

  const _handleForClickEvent = (e) => {
    if (domEle.contains(e.target)) {
      setIsClickOutside(false);
    } else {
      setIsClickOutside(true);
      onClickOutside && onClickOutside();
    }
  };

  React.useEffect(() => {
    if (!domEle) {
      return;
    }

    window.addEventListener('click', _handleForClickEvent, {
      capture: true,
      once: true,
    });

    return () => window.removeEventListener('click', _handleForClickEvent);
  }, [domEle, onClickOutside]);

  return {isClickOutside};
}

export default useClickOutside;
