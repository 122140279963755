import queryString from 'query-string';

function queryParams(string) {
  try {
    let stringToParse =
      string || (typeof window !== 'undefined' ? window.location.search : '');
    return queryString.parse(stringToParse);
  } catch (err) {
    return {};
  }
}

export default queryParams;
