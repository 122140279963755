const nodeFetch = require('node-fetch');
const config = require('../data.json');
const secureFormat = require('./domain/SecureFormat');
const {getOutlet} = require('reconnect.js');
const _host = config.apiHost;
const _jstorageHost = config.jstorageHost;
const _jstorageHost2 = config.jstoreHost;
const _consoleHost = config.consoleHost;
const _s3Host = config.s3Host;
const _authHost = config.authHost;
const _storageHost = config.storageHost;
const _clientId = config.clientId;
const _siteBuildHookUrl = config.siteBuildHookUrl;
let _token = null;
let _refreshToken = null;

const URL_PATH = require('./UrlPath');

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ENDPOINT_TYPE = {
  _legacyJstorageV1: 'jstorage',
  jstorage: 'jstroage2',
  default: 'default',
  custom: 'custom',
};

const actionsOutlet = getOutlet('actions');

const _fetch = async (
  url = '',
  {
    method = 'GET',
    headers = {},
    hostType = ENDPOINT_TYPE.default,
    data = null,
    secure = true,
    withHost = false,
    ...options
  } = {},
) => {
  let _resultUrl = '';
  const _hasTokenInUrl = url.indexOf('token=') !== -1;
  if (hostType === ENDPOINT_TYPE.custom) {
    _resultUrl = `${url}`;
  } else if (
    hostType === ENDPOINT_TYPE._legacyJstorageV1 ||
    hostType === ENDPOINT_TYPE.jstorage
  ) {
    let _url = url;
    if (url.indexOf('?') !== -1) {
      // if url already has token, then should not append client_id, otherwise api will decline our request
      _url = _hasTokenInUrl ? `${url}` : `${url}&client_id=${_clientId}`;
    } else {
      _url = _hasTokenInUrl ? `${url}` : `${url}?client_id=${_clientId}`;
    }

    _resultUrl = `${
      hostType === ENDPOINT_TYPE._legacyJstorageV1
        ? _jstorageHost
        : _jstorageHost2
    }${_url}`;
  } else {
    _resultUrl = `${_host}${url}`;
  }

  let __fetch = null;
  try {
    __fetch = fetch;
  } catch (err) {
    __fetch = nodeFetch;
  }

  let _data = data;
  if (hostType === ENDPOINT_TYPE.jstorage && _data) {
    _data = JSON.parse(
      JSON.stringify(data).replace(/"id":"(.*?)"/g, '"_id": {"$oid": "$1"}'),
    );
  }

  let resp = await __fetch(_resultUrl, {
    ...options,
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(secure && _token && !_hasTokenInUrl
        ? {Authorization: `${_token}`}
        : {}),
      ...headers,
    },
    body: _data !== null ? JSON.stringify(_data) : null,
  });

  if (`${resp.status}`[0] !== '2') {
    let _errorData = {};
    try {
      _errorData = await resp.json();
    } catch (err) {
      console.error(err);
    }

    // handle for api traffic bottleneck, wait 1.5 sec and fetch again
    if (resp.status === 429) {
      await delay(1500);
      try {
        let resp = await _fetch(url, {
          method,
          headers,
          hostType,
          data,
          secure,
          withHost,
          ...options,
        });
        return resp;
      } catch (err) {
        throw err;
      }
    }

    // handle for access token expired, refresh token and fetch again
    if (resp.status === 403 || resp.status === 410) {
      if (
        _errorData.error === 'permission_denied' &&
        _errorData.detail === 'Signature has expired' &&
        _token &&
        _refreshToken
      ) {
        try {
          let refreshTokenResp = await _fetch(
            `${_authHost}/jwt/access?refresh_token=${_refreshToken}`,
            {
              method: 'GET',
              hostType: ENDPOINT_TYPE.custom,
            },
          );
          _token = refreshTokenResp.token;
          if (_hasTokenInUrl) {
            url = url.replace(/(\?|&)(token=)[^\&]+/, `$1$2${_token}`);
          }

          let resp = await _fetch(url, {
            method,
            headers,
            hostType,
            data,
            secure,
            withHost,
            ...options,
          });

          return resp;
        } catch (err) {
          throw err;
        }
      }
    }

    throw {
      ..._errorData,
      status: resp.status,
    };
  }

  let result = null;
  try {
    result = await resp.json();
    if (hostType === ENDPOINT_TYPE.jstorage) {
      if (result) {
        if (result.results) {
          result.results = result.results.map(secureFormat.oid2id);
        } else if (Array.isArray(result)) {
          result = result.map(secureFormat.oid2id);
        } else if (result._id) {
          result = secureFormat.oid2id(result);
        }
      }
    }
  } catch (err) {
    result = {
      status: resp.status,
      error: 'fail to parse response data',
    };
  }
  return result;
};

function setToken(token) {
  _token = `${token}`;
}

function removeToken() {
  _token = null;
}

function setRefreshToken(token) {
  _refreshToken = `${token}`;
}

function removeRefreshToken() {
  _refreshToken = null;
}

function _test403() {
  return _fetch(
    `https://run.mocky.io/v3/603c6e75-ac27-4515-9f3b-4542669721c4?token=TEST`,
    {
      hostType: ENDPOINT_TYPE.custom,
    },
  );
}

async function echo(string) {
  return _fetch('/my-endpoint', {
    method: 'POST',
    data: {
      name: 'Cheers',
    },
  });
}

async function refreshToken(refreshToken) {
  let resp = await _fetch(
    `${_authHost}/jwt/access?refresh_token=${refreshToken}`,
    {
      method: 'GET',
      hostType: ENDPOINT_TYPE.custom,
    },
  );
  _token = resp.token;
  _refreshToken = refreshToken;
  return resp.token;
}

async function staffLogin({username, password}) {
  return _fetch('/staff/authentication/login', {
    method: 'POST',
    data: {username, password},
  });
}

async function staffFetchProfile(token) {
  let resp = await _fetch(`/staff/authentication/profile?token=${token}`, {
    method: 'GET',
  });
  return resp;
}

async function staffChangePw({oldPassword, newPassword}) {
  let resp = await _fetch(
    `/staff/authentication/reset_password?token=${_token}`,
    {
      method: 'POST',
      data: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    },
  );
  return resp;
}

async function login({username, password}) {
  return _fetch('/authentication/login', {
    method: 'POST',
    data: {username, password},
  });
}

async function fetchProfile(token) {
  let resp = await _fetch(`/document/profile/find?token=${token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
    },
  });
  return resp[0];
}

async function refreshProfile() {
  let resp = await _fetch(`/document/profile/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
    },
  });
  return resp[0];
}

async function updateProfile(data) {
  let resp = await _fetch(`/document/profile/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {owner: data.owner}},
  });
  return resp;
}

async function preRegisterPhoneValidate(phone) {
  let resp = await _fetch('/authentication/sendotp/register', {
    method: 'POST',
    data: {phone},
  });
  return resp;
}

async function _nonVerifyPhoneRegister(data) {
  let resp = await _fetch('/authentication/not_verify_phone_register', {
    method: 'POST',
    data: {username: data.phone, password: data.password, email: data.email},
  });

  return resp;
}

async function _updateNonVerifyPhone(phone) {
  let resp = await _fetch(`/authentication/update_phone?token=${_token}`, {
    method: 'POST',
    data: {phone},
  });

  return resp;
}

async function preForgetPwPhoneValidate(phone) {
  let resp = await _fetch('/authentication/sendotp/forget_password', {
    method: 'POST',
    data: {phone},
  });
  return resp;
}

async function preValidatePhone(phone) {
  let resp = await _fetch('/authentication/sendotp/verify_phone', {
    method: 'POST',
    data: {phone},
  });
  return resp;
}

async function verifyOtp({vid, code}) {
  let resp = await _fetch('/authentication/otp/verify', {
    method: 'POST',
    data: {vid, code},
  });
  return resp;
}

async function userPhoneValidate(accessToken) {
  let resp = await _fetch(
    `/authentication/verify_phone?access_token=${accessToken}&token=${_token}`,
    {
      method: 'GET',
    },
  );
  return resp;
}

async function register({password, accessToken, email}) {
  let resp = await _fetch(
    `/authentication/register?access_token=${accessToken}`,
    {
      method: 'POST',
      data: {password, email},
    },
  );
  return resp;
}

async function resetForgetPw({password, accessToken}) {
  let resp = await _fetch(
    `/authentication/forget_password?access_token=${accessToken}`,
    {
      method: 'POST',
      data: {new_password: password},
    },
  );
  return resp;
}

async function changePw({password, newPassword}) {
  let resp = await _fetch(`/authentication/reset_password?token=${_token}`, {
    method: 'POST',
    data: {
      old_password: password,
      new_password: newPassword,
    },
  });
  return resp;
}

function getGoogleLoginUrl() {
  return `${_host}/authentication/google/redirect`;
}

function getFacebookLoginUrl() {
  return `${_host}/authentication/facebook/redirect`;
}

async function getCategories() {
  let resp = await _fetch(`/document/category/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
    },
  });

  return resp[0] || null;
}

async function getCart() {
  let resp = await _fetch(`/cart?token=${_token}`, {
    method: 'GET',
  });

  return secureFormat.cart(resp);
}

async function addItemToCart(item) {
  let resp = await _fetch(`/cart/item/add?token=${_token}`, {
    method: 'POST',
    data: {
      product_id: item.productId,
      config: {
        qty: item.quantity,
        spec: item.specItemId,
        customize_card: item.customCardInput,
        delivery_date: item.date,
        pick_time: item.pickUpTime,
        logistic_type: item.logisticType,
        entp_stock_id: item.entpStockId || '',
        extra: item.extra || {},
      },
    },
  });

  return secureFormat.cart(resp);
}

async function editItemInCart(idx, item) {
  let resp = await _fetch(`/cart/item/edit?token=${_token}`, {
    method: 'POST',
    data: {
      index: idx,
      config: {
        qty: item.quantity,
        spec: item.specItemId,
        customize_card: item.customCardInput,
        delivery_date: item.date,
        logistic_type: item.logistic_type,
        pick_time: item.pickUpTime,
        entp_stock_id: item.entpStockId || '',
      },
    },
  });

  return secureFormat.cart(resp);
}

async function removeItemFromCart(idx) {
  let resp = await _fetch(`/cart/item/delete?token=${_token}`, {
    method: 'POST',
    data: {
      index: idx,
    },
  });

  return secureFormat.cart(resp);
}

async function editCartConfig(data) {
  let resp = await _fetch(`/cart/edit_config?token=${_token}`, {
    method: 'POST',
    data: {
      ...data,
    },
  });

  return secureFormat.cart(resp);
}

async function onUseDiscount(discountConfig) {
  /*
{
  coupon?: string;
  points?: number;
  gift_points?: number;
  use_full_points?: boolean;
}
  */

  const resp = await _fetch(`/cart/discount/use?token=${_token}`, {
    method: 'post',
    data: {
      ...discountConfig,
    },
  });

  return secureFormat.cart(resp);
}

async function getCheckoutUrl() {
  return `${_host}/checkout?token=${_token}`;
}

async function getProducts(configs = {}) {
  let resp = await _fetch(`/document/product/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      ...configs,
    },
  });

  // with paging config, resp = {results:[], total:0}
  // without paging config, resp = [order]
  if (Array.isArray(resp.results)) {
    return {
      ...resp,
      results: resp.results.map(secureFormat.product),
    };
  }

  return resp.map(secureFormat.product);
}

async function getOrderById(id) {
  let resp = await _fetch(`/document/order/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {id},
    },
  });

  return secureFormat.order(resp);
}

async function getOrderByOrderId(id) {
  let resp = await _fetch(`/document/order/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {order_id: id},
    },
  });

  return secureFormat.order(resp);
}

async function getOrderByOrderNumber(no) {
  let resp = await _fetch(`/document/order/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {order_number: no},
    },
  });

  return secureFormat.order(resp);
}

async function getPreOrderByOrderNumber(no) {
  let resp = await _fetch(`/document/checkout/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {order_number: no},
    },
  });

  return secureFormat.preOrder(resp);
}

async function getOrders(configs = {}) {
  let resp = await _fetch(`/document/order/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      ...configs,
    },
  });

  // with paging config, resp = {results:[], total:0}
  // without paging config, resp = [order]
  if (Array.isArray(resp.results)) {
    return {
      ...resp,
      results: resp.results.map(secureFormat.order),
    };
  }

  return resp.map(secureFormat.order);
}

async function getProductById(id) {
  let resp = await _fetch(`/document/product/find-one`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {id},
    },
  });

  return secureFormat.product(resp);
}

async function fetchCalendar({from, to, logisticType, itemId}) {
  // logisticType: "self_pick" | "home"
  return _fetch(
    `/calendar?from_date=${from}&to_date=${to}&logistic_type=${logisticType}&item_id=${itemId}`,
    {
      method: 'GET',
    },
  );
}

async function fetchEntpStocks(ids) {
  const resp = await _fetch(`/document/entp_stock/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {
        $or: ids.map((id) => ({id: id})),
      },
    },
    secure: false,
  });

  return resp;
}

async function fetchEntpStockById(entpStockId) {
  const resp = await _fetch(`/document/entp_stock/find-one`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {
        id: entpStockId,
      },
    },
    secure: false,
  });

  return resp;
}

async function createForetasteOrder(data) {
  const resp = await _fetch(`/document/foretaste/create`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data},
    secure: false,
  });
  return resp;
}

async function getUploadToken() {
  const resp = await _fetch(`/authentication/dummy`, {
    method: 'POST',
    secure: false,
  });
  return resp;
}

async function storagePresigned({file, token}) {
  return await _fetch(`${_storageHost}/storage/presigned/url?token=${token}`, {
    hostType: ENDPOINT_TYPE.custom,
    method: 'POST',
    data: {acl: 'public-read', 'Content-Type': file.type, key: file.name},
  });
}

async function getNewsList(configs = {}) {
  const resp = await _fetch(`/document/Article_Default/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      paging: {
        offset: 0,
        limit: 10,
      },
      sorting: ['-created'],
      ...configs,
      query: {label: '__NEWS__'},
    },
    secure: false,
  });

  return resp;
}

async function getArticleById(oid) {
  const resp = await _fetch(`/document/Article_Default/find-one`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {query: {_id: {$oid: oid}}},
    secure: false,
  });
  return resp;
}

async function getNewsById(oid) {
  const resp = await _fetch(`/document/Article_Default/find-one`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {query: {_id: {$oid: oid}}},
    secure: false,
  });
  return resp;
}

async function getHomeData() {
  let resp = await _fetch(`/document/home/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
    },
  });

  return resp[0] || null;
}

async function sendContactForm(data) {
  let resp = await _fetch(`/contact_us/request`, {
    method: 'POST',
    data,
  });

  return resp;
}

function getShareLink({image, redirectUrl, title, description}) {
  return `${_host}/sharing/link?image=${image}&title=${title}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

async function staffUpdateHomeData(data) {
  const resp = await _fetch(`/document/home/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {id: data.id}},
    secure: false,
  });

  return resp;
}

async function staffCreateProduct(data) {
  const resp = await _fetch(`/document/product/create?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data},
    secure: false,
  });
  return resp;
}

async function staffUpdateProduct(data) {
  const resp = await _fetch(`/document/product/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {id: data.id}},
    secure: false,
  });
  return resp;
}

async function staffDeleteProduct(id) {
  const resp = await _fetch(
    `/document/product/find-one/delete?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {query: {id}},
      secure: false,
    },
  );
  return resp;
}

async function staffCreateCalendar(data) {
  const resp = await _fetch(`/calendar/create`, {
    method: 'POST',
    data: {
      item_id: data.item_id,
      logistic_type: data.logistic_type,
      production_date: data.production_date,
      delivery_date: data.delivery_date,
    },
  });
  return resp;
}

async function staffMultiDeleteCalendar(data) {
  const resp = await _fetch(
    `/calendar/delete?item_id=${data.item_id}&logistic_type=${data.logistic_type}&from_date=${data.from_date}&to_date=${data.to_date}`,
    {
      method: 'DELETE',
    },
  );
  return resp;
}

async function staffMultiCreateCalendar(data) {
  const resp = await _fetch(`/calendar/multiple/create`, {
    method: 'POST',
    data,
  });
  return resp;
}

async function staffMultiCreateEntpCalendar(data) {
  // note: this api create both entp_stock and calendar data (related by entp_stock_id)
  const resp = await _fetch(`/calendar/mult-entp/create`, {
    method: 'POST',
    data,
  });
  return resp;
}

async function staffCreateStock(data) {
  const resp = await _fetch(`/stock/create`, {
    method: 'POST',
    data,
  });
  return resp;
}

async function staffGetOrderStats(_data) {
  let data = {..._data};
  delete data.type;
  const resp = await _fetch(`/order/stats/${_data.type}?token=${_token}`, {
    method: 'POST',
    data,
  });
  return resp;
}

async function staffUpdateOrder(data) {
  let _cleanUpData = {...data};
  // note: workaround for date jstorage validation in payment_transcation_detail field
  delete _cleanUpData.payment_transcation_detail;
  // note: for v2 and v1 jstorage migration data, we cannot bring old product.id to jstorage
  // 12/24 note: `user edit item pick_up time` feature, suppose not to edit v1 order items (will blocked by product string id)
  // delete _cleanUpData.items;
  delete _cleanUpData.stocks;

  const resp = await _fetch(`/document/order/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data: _cleanUpData, query: {id: data.id}},
    secure: false,
  });
  return resp;
}

async function staffMultiUpdateOrder(data) {
  const resp = await _fetch(`/order/multiple/update?token=${_token}`, {
    method: 'POST',
    data,
    secure: false,
  });

  return resp;
}

async function staffGetForetasteOrders(configs = {}) {
  let resp = await _fetch(`/document/foretaste/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      ...configs,
    },
  });

  // with paging config, resp = {results:[], total:0}
  // without paging config, resp = [order]
  if (Array.isArray(resp.results)) {
    return {
      ...resp,
      results: resp.results.map(secureFormat.foretaste),
    };
  }

  return resp.map(secureFormat.foretaste);
}

async function staffGetForetasteOrderById(id) {
  let resp = await _fetch(`/document/foretaste/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {id},
    },
  });

  return secureFormat.foretaste(resp);
}

async function staffUpdateForetasteOrder(data) {
  const resp = await _fetch(`/document/foretaste/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {id: data.id}},
    secure: false,
  });
  return secureFormat.foretaste(resp);
}

async function staffDeleteForetasteOrder(id) {
  const resp = await _fetch(
    `/document/foretaste/find-one/delete?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {query: {id}},
      secure: false,
    },
  );
  return resp;
}

async function staffGetContactForms(configs = {}) {
  let resp = await _fetch(`/document/contact_us/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      ...configs,
    },
  });

  // with paging config, resp = {results:[], total:0}
  // without paging config, resp = [order]
  if (Array.isArray(resp.results)) {
    return {
      ...resp,
      results: resp.results.map(secureFormat.contactUs),
    };
  }

  return resp.map(secureFormat.contactUs);
}

async function staffGetContactFormById(id) {
  let resp = await _fetch(`/document/contact_us/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {id},
    },
  });

  return secureFormat.contactUs(resp);
}

async function staffUpdateContactForm(data) {
  const resp = await _fetch(`/document/contact_us/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {id: data.id}},
    secure: false,
  });
  return secureFormat.contactUs(resp);
}

async function staffDeleteContactForm(id) {
  const resp = await _fetch(
    `/document/contact_us/find-one/delete?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {query: {id}},
      secure: false,
    },
  );
  return resp;
}

async function staffCreateArticle() {
  const resp = await _fetch(
    `/document/Article_Default/create?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {
        data: {
          title: '',
          content: [{type: 'p', children: [{text: ' '}]}],
          // label: []
        },
      },
      secure: false,
    },
  );
  return resp;
}

async function staffCreateNews() {
  const resp = await _fetch(
    `/document/Article_Default/create?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {
        data: {
          title: '',
          content: [{type: 'p', children: [{text: ' '}]}],
          label: ['__draft-NEWS__'],
        },
      },
      secure: false,
    },
  );
  return resp;
}

async function staffUpdateNewsById(id, data) {
  delete data.id; // this id is not true id, we have transformed again when we get data
  const resp = await _fetch(
    `/document/Article_Default/update?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {
        data,
        query: {
          _id: {
            $oid: id,
          },
        },
      },
      secure: false,
    },
  );
  return resp;
}

async function staffGetPreviewArticleUrl(id) {
  return `${URL_PATH.articlePreview}?id=${id}`;
}

async function staffGetArticleEditorUrl(id) {
  return `${URL_PATH.dashboardArticleEditor}?id=${id}&edit=Y`;
}

async function staffDeleteArticle(id) {
  const resp = await _fetch(
    `/document/Article_Default/find-one/delete?token=${_token}`,
    {
      hostType: ENDPOINT_TYPE.jstorage,
      method: 'POST',
      data: {query: {id}},
      secure: false,
    },
  );
  return resp;
}

async function staffUpdateCategory(data) {
  const resp = await _fetch(`/document/category/update?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {data, query: {id: data.id}},
    secure: false,
  });
  return resp;
}

async function staffTriggerProductCatFileUpdate() {
  const productResp = await _fetch(`/document/product/cache?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      sorting: ['-created'],
      projection: {
        additional_product_stock: 0,
      },
      key: 'product',
      acl: 'public-read',
    },
    secure: false,
  });

  const catResp = await _fetch(`/document/category/cache?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      sorting: ['-created'],
      key: 'category',
      acl: 'public-read',
    },
    secure: false,
  });
  return {
    product: productResp.url,
    category: catResp.url,
  };
}

async function getFileCategories() {
  const json = await _fetch(`${_s3Host}/category`, {
    hostType: ENDPOINT_TYPE.custom,
    method: 'GET',
    secure: false,
  });

  return secureFormat.oid2id(json[0]);
}

async function getFileProducts() {
  const json = await _fetch(`${_s3Host}/product`, {
    hostType: ENDPOINT_TYPE.custom,
    method: 'GET',
    secure: false,
  });

  return json.map(secureFormat.oid2id).map(secureFormat.product);
}

async function staffGetMembers(configs = {}) {
  let resp = await _fetch(`/document/profile/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
      ...configs,
    },
  });

  // with paging config, resp = {results:[], total:0}
  // without paging config, resp = [member]
  if (Array.isArray(resp.results)) {
    return {
      ...resp,
      results: resp.results,
    };
  }

  return resp;
}

async function staffGetMemberById(id) {
  let resp = await _fetch(`/document/profile/find-one?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {owner: id},
    },
  });

  return resp;
}

async function staffGetStaffs() {
  let resp = await _fetch(`/document/staff/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {},
    },
  });

  return resp;
}

async function staffIssueInvoice(orderId) {
  let resp = await _fetch(`/invoice/issue?token=${_token}`, {
    method: 'POST',
    data: {
      order_id: orderId,
    },
  });

  return resp;
}

async function staffVoidInvoice(orderId) {
  let resp = await _fetch(`/invoice/void?token=${_token}`, {
    method: 'POST',
    data: {
      order_id: orderId,
    },
  });

  return resp;
}

async function staffGetNewsList(configs = {}) {
  const resp = await _fetch(`/document/Article_Default/find`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      paging: {
        offset: 0,
        limit: 10,
      },
      sorting: ['-created'],
      query: {$or: [{label: '__NEWS__'}, {label: '__draft-NEWS__'}]},
      ...configs,
    },
    secure: false,
  });

  return {
    ...resp,
    results: resp.results.map(secureFormat.oid2id),
  };
}

async function staffResetUserPassword(userId) {
  return await _fetch(
    `/staff/authentication/reset_user_password?token=${_token}`,
    {
      method: 'POST',
      data: {
        user_id: userId,
      },
    },
  );
}

async function staffDeleteUser(userId) {
  await _fetch(`/document/user/find-one/delete?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {
        id: userId,
      },
    },
  });
  await _fetch(`/document/profile/find-one/delete?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {
        owner: userId,
      },
    },
  });

  await _fetch(`/document/cart/find-one/delete?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {
      query: {
        owner: userId,
      },
    },
  });
}

async function staffGenerateInvoice(order) {
  const data = order.invoice_data;
  return await _fetch(`/invoice/generate?token=${_token}`, {
    method: 'POST',
    data: {
      order_id: order.order_id,
      invoice_detail_data: {
        invoiceNumber: data.invoiceNumber,
        checkNumber: data.checkNumber,
        invoiceDate: data.invoiceDate,
        invoiceTime: data.invoiceTime,
        texclusiveAmount: data.texclusiveAmount,
        oexclusiveAmount: data.oexclusiveAmount,
        zexclusiveAmount: data.zexclusiveAmount,
      },
    },
  });
}

async function staffGetStocks(query) {
  return await _fetch(`/document/stock/find?token=${_token}`, {
    hostType: ENDPOINT_TYPE.jstorage,
    method: 'POST',
    data: {query},
  });
}

async function staffCreateUser(data) {
  const resp = await _fetch(
    `/staff/authentication/create_user?token=${_token}`,
    {
      method: 'POST',
      data,
    },
  );
  return resp;
}

async function staffVerifyPhone(data) {
  const resp = await _fetch(
    `/staff/authentication/verify_phone?token=${_token}`,
    {
      method: 'POST',
      data,
    },
  );
  return resp;
}

async function staffUpdateOrderToErp(data) {
  return await _fetch(`/staff/authentication/to_erp?token=${_token}`, {
    method: 'POST',
    data: {
      order_id: data.order_ids,
    },
  });
}

async function _mockServer429() {
  const resp = await _fetch(
    `https://run.mocky.io/v3/7daa3451-0fe8-4000-8469-c88666efaf03`,
    {
      hostType: ENDPOINT_TYPE.custom,
      method: 'GET',
      secure: false,
    },
  );

  return resp;
}

/**
 * BULK_API STARTS HERE
 */
async function bulkEcho(data) {
  console.log('bulk api:', JSON.stringify(data));
}

module.exports = {
  setToken,
  removeToken,
  setRefreshToken,
  removeRefreshToken,
  _test403,
  echo,
  refreshToken,
  staffLogin,
  staffFetchProfile,
  staffChangePw,
  login,
  updateProfile,
  refreshProfile,
  preRegisterPhoneValidate,
  preForgetPwPhoneValidate,
  preValidatePhone,
  verifyOtp,
  userPhoneValidate,
  register,
  _nonVerifyPhoneRegister,
  _updateNonVerifyPhone,
  resetForgetPw,
  changePw,
  getGoogleLoginUrl,
  getFacebookLoginUrl,
  fetchProfile,
  getCategories,
  getCart,
  addItemToCart,
  editItemInCart,
  removeItemFromCart,
  editCartConfig,
  onUseDiscount,
  getCheckoutUrl,
  getOrderById,
  getOrderByOrderId,
  getOrderByOrderNumber,
  getPreOrderByOrderNumber,
  getOrders,
  getProducts,
  getProductById,
  fetchCalendar,
  fetchEntpStocks,
  fetchEntpStockById,
  createForetasteOrder,
  getUploadToken,
  storagePresigned,
  getArticleById,
  getNewsById,
  getNewsList,
  getHomeData,
  sendContactForm,
  getShareLink,
  staffUpdateHomeData,
  staffCreateProduct,
  staffUpdateProduct,
  staffDeleteProduct,
  staffCreateCalendar,
  staffMultiCreateCalendar,
  staffMultiCreateEntpCalendar,
  staffMultiDeleteCalendar,
  staffCreateStock,
  staffGetOrderStats,
  staffUpdateOrder,
  staffMultiUpdateOrder,
  staffGetForetasteOrders,
  staffGetForetasteOrderById,
  staffUpdateForetasteOrder,
  staffDeleteForetasteOrder,
  staffGetContactForms,
  staffGetContactFormById,
  staffUpdateContactForm,
  staffDeleteContactForm,
  staffGetPreviewArticleUrl,
  staffGetArticleEditorUrl,
  staffCreateNews,
  staffUpdateNewsById,
  staffDeleteArticle,
  staffCreateArticle,
  staffUpdateCategory,
  staffTriggerProductCatFileUpdate,
  getFileCategories,
  getFileProducts,
  staffGetMembers,
  staffGetMemberById,
  staffGetStaffs,
  staffIssueInvoice,
  staffVoidInvoice,
  staffGetNewsList,
  staffResetUserPassword,
  staffDeleteUser,
  staffGenerateInvoice,
  staffGetStocks,
  staffCreateUser,
  staffVerifyPhone,
  staffUpdateOrderToErp,
  // bulk api starts here
  bulkEcho,
};
