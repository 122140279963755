// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-bulk-templates-bulk-product-index-js": () => import("./../../../src/bulk_templates/BulkProduct/index.js" /* webpackChunkName: "component---src-bulk-templates-bulk-product-index-js" */),
  "component---src-bulk-templates-bulk-product-list-index-js": () => import("./../../../src/bulk_templates/BulkProductList/index.js" /* webpackChunkName: "component---src-bulk-templates-bulk-product-list-index-js" */),
  "component---src-bulk-templates-dashboard-bulk-order-list-page-js": () => import("./../../../src/bulk_templates/Dashboard/BulkOrderListPage.js" /* webpackChunkName: "component---src-bulk-templates-dashboard-bulk-order-list-page-js" */),
  "component---src-bulk-templates-dashboard-index-js": () => import("./../../../src/bulk_templates/Dashboard/index.js" /* webpackChunkName: "component---src-bulk-templates-dashboard-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-checkout-cart-page-js": () => import("./../../../src/templates/Checkout/CartPage.js" /* webpackChunkName: "component---src-templates-checkout-cart-page-js" */),
  "component---src-templates-dashboard-article-editor-page-js": () => import("./../../../src/templates/Dashboard/ArticleEditorPage.js" /* webpackChunkName: "component---src-templates-dashboard-article-editor-page-js" */),
  "component---src-templates-dashboard-categories-page-js": () => import("./../../../src/templates/Dashboard/CategoriesPage.js" /* webpackChunkName: "component---src-templates-dashboard-categories-page-js" */),
  "component---src-templates-dashboard-config-ec-page-js": () => import("./../../../src/templates/Dashboard/ConfigEcPage.js" /* webpackChunkName: "component---src-templates-dashboard-config-ec-page-js" */),
  "component---src-templates-dashboard-contact-us-forms-page-js": () => import("./../../../src/templates/Dashboard/ContactUsFormsPage.js" /* webpackChunkName: "component---src-templates-dashboard-contact-us-forms-page-js" */),
  "component---src-templates-dashboard-coupon-page-index-js": () => import("./../../../src/templates/Dashboard/CouponPage/index.js" /* webpackChunkName: "component---src-templates-dashboard-coupon-page-index-js" */),
  "component---src-templates-dashboard-foretastes-page-js": () => import("./../../../src/templates/Dashboard/ForetastesPage.js" /* webpackChunkName: "component---src-templates-dashboard-foretastes-page-js" */),
  "component---src-templates-dashboard-index-js": () => import("./../../../src/templates/Dashboard/index.js" /* webpackChunkName: "component---src-templates-dashboard-index-js" */),
  "component---src-templates-dashboard-login-index-js": () => import("./../../../src/templates/DashboardLogin/index.js" /* webpackChunkName: "component---src-templates-dashboard-login-index-js" */),
  "component---src-templates-dashboard-members-page-js": () => import("./../../../src/templates/Dashboard/MembersPage.js" /* webpackChunkName: "component---src-templates-dashboard-members-page-js" */),
  "component---src-templates-dashboard-news-page-js": () => import("./../../../src/templates/Dashboard/NewsPage.js" /* webpackChunkName: "component---src-templates-dashboard-news-page-js" */),
  "component---src-templates-dashboard-orders-page-js": () => import("./../../../src/templates/Dashboard/OrdersPage.js" /* webpackChunkName: "component---src-templates-dashboard-orders-page-js" */),
  "component---src-templates-dashboard-products-page-js": () => import("./../../../src/templates/Dashboard/ProductsPage.js" /* webpackChunkName: "component---src-templates-dashboard-products-page-js" */),
  "component---src-templates-dashboard-settings-page-js": () => import("./../../../src/templates/Dashboard/SettingsPage.js" /* webpackChunkName: "component---src-templates-dashboard-settings-page-js" */),
  "component---src-templates-dashboard-staffs-page-js": () => import("./../../../src/templates/Dashboard/StaffsPage.js" /* webpackChunkName: "component---src-templates-dashboard-staffs-page-js" */),
  "component---src-templates-dashboard-stock-preview-page-js": () => import("./../../../src/templates/Dashboard/StockPreviewPage.js" /* webpackChunkName: "component---src-templates-dashboard-stock-preview-page-js" */),
  "component---src-templates-dashboard-test-js": () => import("./../../../src/templates/Dashboard/test.js" /* webpackChunkName: "component---src-templates-dashboard-test-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/templates/Faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-foretaste-index-js": () => import("./../../../src/templates/Foretaste/index.js" /* webpackChunkName: "component---src-templates-foretaste-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-list-index-js": () => import("./../../../src/templates/NewsList/index.js" /* webpackChunkName: "component---src-templates-news-list-index-js" */),
  "component---src-templates-order-index-js": () => import("./../../../src/templates/Order/index.js" /* webpackChunkName: "component---src-templates-order-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-product-product-preview-page-js": () => import("./../../../src/templates/Product/ProductPreviewPage.js" /* webpackChunkName: "component---src-templates-product-product-preview-page-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-index-js": () => import("./../../../src/templates/Register/index.js" /* webpackChunkName: "component---src-templates-register-index-js" */)
}

