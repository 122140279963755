import React from 'react';
import './App';
import Api from './Api';
import config from '../data.json';
import {message} from 'antd';
import {INVALID_CART_ERROR_KEYS} from './domain/Constants';
import BulkAppContextActions from './AppContext.bulk';
import URL_PATH from './UrlPath';
import Gtag from './utils/gtag';
import GlobalSpinner from './components/GlobalSpinner';
import GlobalModal from './components/GlobalModal';

const _clientId = config.clientId;
const ENABLE_SDK_USER = true;
const {getOutlet} = require('reconnect.js');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

const Context = React.createContext();
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let actions = {};

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      // TODO: update user outlet for new token / no need for refresh token
      // if (result) {
      //   console.log('onError autoLogin success, fetch resource again', result);
      //   return req(url, payload, {ignoreOnErrorHook: true});
      // }
      // console.log('onError autoLogin failure, throw original error', result);
      // throw resp;
    }
  },
});

function Provider(props) {
  const [initialized, setInitialized] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [cart, setCart] = React.useState(null);
  const [staffUser, setStaffUser] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [categoryTree, setCategoryTree] = React.useState(null);
  let _actions = {
    setInitialized: (_initialized) => {
      setInitialized(_initialized);
    },

    setProducts: (products) => {
      setProducts(products);
    },

    setCategories: (tree) => {
      setCategoryTree(tree);
    },

    setOpenMenu: (shouldOpen) => {
      setIsMenuOpen(shouldOpen);
    },

    refreshToken: async (refreshToken, isStaff = false) => {
      try {
        let token = await Api.refreshToken(refreshToken);
        Api.setRefreshToken(refreshToken);
        return token;
      } catch (err) {
        if (err && `${err.status}`[0] !== '5') {
          isStaff
            ? window.localStorage.removeItem('staff-refresh-token')
            : window.localStorage.removeItem('refresh-token');

          UserOutlet.update(null);
        }
      }
    },

    staffLogin: async ({username, password}) => {
      let staffUser = await Api.staffLogin({username, password});
      UserOutlet.update({
        ...staffUser,
        username: username,
        token: staffUser.token,
        client: _clientId,
      });
      Api.setToken(staffUser.token);
      Api.setRefreshToken(staffUser.refresh);
      setStaffUser(staffUser);
      window.localStorage.setItem('staff-refresh-token', staffUser.refresh);
      return staffUser;
    },

    staffLogout: async () => {
      window.localStorage.removeItem('staff-refresh-token');
      Api.setToken(null);
      Api.setRefreshToken(null);
      setStaffUser(null);
      UserOutlet.update(null);
      return null;
    },

    autoStaffLogin: async (token) => {
      try {
        let staffUser = await Api.staffFetchProfile(token);
        Api.setToken(token);
        UserOutlet.update({...staffUser, token, client: _clientId});
        setStaffUser(staffUser);
        return staffUser;
      } catch (err) {
        Api.setToken(null);
        setStaffUser(null);
        UserOutlet.update(null);
        throw err;
      }
    },

    staffChangePw: async ({oldPassword, newPassword}) => {
      let resp = await Api.staffChangePw({oldPassword, newPassword});
      return resp;
    },

    login: async ({username, password}) => {
      let user = await Api.login({username, password});
      let token = user.token;
      let refreshToken = user.refresh;
      let profile = await Api.fetchProfile(token);
      Api.setToken(token);
      Api.setRefreshToken(refreshToken);
      setProfile(profile);
      UserOutlet.update({
        username: username,
        ...profile,
        token: user.token,
        client: _clientId,
      });
      Gtag('event', 'login', {
        method: profile.provider,
      });
      Gtag('set', 'user_id', profile.id);
      Gtag('set', 'user_properties', {
        provider: profile.provider,
        email: profile.email,
      });
      try {
        let cart = await Api.getCart();
        setCart(cart);

        // legacy remove dummy cart
        window.localStorage.removeItem('dummy-cart-id');
      } catch (err) {
        console.log(err);
        if (INVALID_CART_ERROR_KEYS.indexOf(err.error) !== -1) {
          message.warn(
            '您的購物車商品已售完，系統已自動移除，感謝您的配合與體諒',
          );
          let cart = await Api.getCart();
          setCart(cart);
        }
      }

      window.localStorage.setItem('refresh-token', refreshToken);
      return profile;
    },

    autoLogin: async (token) => {
      try {
        let profile = await Api.fetchProfile(token);
        Api.setToken(token);
        setProfile(profile);
        Gtag('event', 'login', {
          method: profile.provider,
        });
        Gtag('set', 'user_id', profile.id);
        Gtag('set', 'user_properties', {
          provider: profile.provider,
          email: profile.email,
        });
        try {
          let cart = await Api.getCart();
          setCart(cart);
        } catch (err) {
          if (INVALID_CART_ERROR_KEYS.indexOf(err.error) !== -1) {
            message.warn(
              '您的購物車商品已售完，系統已自動移除，感謝您的配合與體諒',
            );
            let cart = await Api.getCart();
            setCart(cart);
          }
        }
        return profile;
      } catch (err) {
        //
        // window.localStorage.removeItem('token');
        Api.setToken(null);
        setProfile(null);
        setCart(null);
        UserOutlet.update(null);
        throw err;
      }
    },

    logout: async () => {
      window.localStorage.removeItem('refresh-token');
      Api.setToken(null);
      Api.setRefreshToken(null);
      setProfile(null);
      setCart(null);
      UserOutlet.update(null);
      return null;
    },

    refreshProfile: async () => {
      let profile = await Api.refreshProfile();
      setProfile(profile);
      return profile;
    },

    updateProfile: async (data) => {
      let resp = await Api.updateProfile(data);
      setProfile(resp);
      return resp;
    },

    refreshCart: async () => {
      let _cart = await Api.getCart();
      setCart(_cart);
      return _cart;
    },

    preRegisterPhoneValidate: async (phone) => {
      let resp = await Api.preRegisterPhoneValidate(phone);
      return resp;
    },

    _nonVerifyPhoneRegister: async (data) => {
      let resp = await Api._nonVerifyPhoneRegister(data);
      return resp;
    },

    _updateNonVerifyPhone: async (phone) => {
      let resp = await Api._updateNonVerifyPhone(phone);
      return resp;
    },

    preForgetPwPhoneValidate: async (phone) => {
      let resp = await Api.preForgetPwPhoneValidate(phone);
      return resp;
    },

    resetForgetPw: async ({password, accessToken}) => {
      let resp = await Api.resetForgetPw({password, accessToken});
      return resp;
    },

    changePw: async ({password, newPassword}) => {
      let resp = await Api.changePw({password, newPassword});
      return resp;
    },

    verifyOtp: async ({vid, code}) => {
      let resp = await Api.verifyOtp({vid, code});
      return resp;
    },

    preValidatePhone: async (phone) => {
      let resp = await Api.preValidatePhone(phone);
      return resp;
    },

    register: async ({password, accessToken, email}) => {
      let resp = await Api.register({password, accessToken, email});
      Gtag('event', 'sign_up', {method: 'default'});
      return resp;
    },

    userPhoneValidate: async (accessToken) => {
      let resp = await Api.userPhoneValidate(accessToken);
      return resp;
    },

    getGoogleLoginUrl: () => {
      return Api.getGoogleLoginUrl();
    },

    getFacebookLoginUrl: () => {
      return Api.getFacebookLoginUrl();
    },

    getCategories: async () => {
      let resp = await Api.getCategories();
      return resp;
    },

    getFileCategories: async () => {
      let resp = await Api.getFileCategories();
      return resp;
    },

    setCart: async (cart) => {
      setCart(cart);
    },

    addItemToCart: async (data) => {
      let cart = await Api.addItemToCart(data);
      setCart(cart);
      return cart;
    },

    removeItemFromCart: async (idx) => {
      let _cart = await Api.removeItemFromCart(idx);
      setCart(_cart);
      return _cart;
    },

    editItemInCart: async (idx, item) => {
      let _cart = null;
      try {
        _cart = await Api.editItemInCart(idx, item);
        setCart(_cart);
      } catch (err) {
        if (INVALID_CART_ERROR_KEYS.indexOf(err.error) !== -1) {
          message.warn(
            '您的購物車商品已售完，系統已自動移除，感謝您的配合與體諒',
          );
          _cart = await Api.getCart();
          setCart(_cart);
        } else if (err.error === 'invalid_pick_time') {
          message.warn(
            `請確認購物車內取貨時段相同，若不同時段請分開下單，謝謝！`,
          );
        }
      }
      return _cart;
    },

    editCartConfig: async (data) => {
      let _cart = null;
      try {
        _cart = await Api.editCartConfig(data);
        setCart(_cart);
      } catch (err) {
        if (INVALID_CART_ERROR_KEYS.indexOf(err.error) !== -1) {
          message.warn(
            '您的購物車商品已售完，系統已自動移除，感謝您的配合與體諒',
          );
          _cart = await Api.getCart();
          setCart(_cart);
        }
      }
      return _cart;
    },

    onUseDiscount: async (data) => {
      let _cart = null;
      try {
        _cart = await Api.onUseDiscount(data);
        setCart(_cart);
      } catch (err) {
        throw err;
      }
      return _cart;
    },

    getCheckoutUrl: async (data) => {
      let url = await Api.getCheckoutUrl(data);
      return url;
    },

    getOrderById: async (id) => {
      let order = await Api.getOrderById(id);
      return order;
    },

    getOrderByOrderId: async (id) => {
      let order = await Api.getOrderByOrderId(id);
      return order;
    },

    getOrderByOrderNumber: async (no) => {
      let order = await Api.getOrderByOrderNumber(no);
      return order;
    },

    getPreOrderByOrderNumber: async (no) => {
      let preOrder = await Api.getPreOrderByOrderNumber(no);
      return preOrder;
    },

    getOrders: async (configs = {}) => {
      let orderList = await Api.getOrders(configs);
      return orderList;
    },

    getProductById: async (id) => {
      return await Api.getProductById(id);
    },

    getProducts: async (configs) => {
      let resp = await Api.getProducts(configs);
      return resp;
    },

    getFileProducts: async () => {
      let resp = await Api.getFileProducts();
      return resp;
    },

    fetchCalendar: async ({from, to, itemId, logisticType}) => {
      return Api.fetchCalendar({from, to, itemId, logisticType});
    },

    fetchEntpStocks: async (ids) => {
      return Api.fetchEntpStocks(ids);
    },

    fetchEntpStockById: async (entpStockId) => {
      return Api.fetchEntpStockById(entpStockId);
    },

    createForetasteOrder: async (values) => {
      return Api.createForetasteOrder(values);
    },

    getUploadToken: async () => {
      return Api.getUploadToken();
    },

    storagePresigned: async ({file, token}) => {
      return Api.storagePresigned({file, token});
    },

    getArticleById: async (id) => {
      return Api.getArticleById(id);
    },

    getNewsList: async (configs) => {
      return Api.getNewsList(configs);
    },

    getNewsById: async (id) => {
      return Api.getNewsById(id);
    },

    getHomeData: async () => {
      return Api.getHomeData();
    },

    sendContactForm: async (data) => {
      return Api.sendContactForm(data);
    },

    getShareLink: (data) => {
      return Api.getShareLink(data);
    },

    getProductShareLink: (product) => {
      try {
        let _productDescript = product.description || '';
        _productDescript =
          _productDescript.length > 10
            ? _productDescript.slice(0, 10) + '...'
            : _productDescript;
        let data = {
          image: product.image,
          title: encodeURIComponent(product.name),
          description: encodeURIComponent(_productDescript),
          redirectUrl: `${config.siteUrl}/${URL_PATH.product}?id=${product.id}`,
        };
        return Api.getShareLink(data);
      } catch (err) {
        return '';
      }
    },

    // dashboard
    staffUpdateHomeData: async (data) => {
      return Api.staffUpdateHomeData(data);
    },

    staffUpdateProduct: async (data) => {
      return Api.staffUpdateProduct(data);
    },

    staffCreateProduct: async (data) => {
      return Api.staffCreateProduct(data);
    },

    staffDeleteProduct: async (id) => {
      return Api.staffDeleteProduct(id);
    },

    staffCreateCalendar: async (data) => {
      return Api.staffCreateCalendar(data);
    },

    staffMultiDeleteCalendar: async (data) => {
      return Api.staffMultiDeleteCalendar(data);
    },

    staffMultiCreateCalendar: async (data) => {
      return Api.staffMultiCreateCalendar(data);
    },

    staffMultiCreateEntpCalendar: async (data) => {
      return Api.staffMultiCreateEntpCalendar(data);
    },

    staffCreateStock: async (data) => {
      return Api.staffCreateStock(data);
    },

    staffGetOrderStats: async (data) => {
      return Api.staffGetOrderStats(data);
    },

    staffUpdateOrder: async (data) => {
      return Api.staffUpdateOrder(data);
    },

    staffMultiUpdateOrder: async (data) => {
      return Api.staffMultiUpdateOrder(data);
    },

    staffGetForetasteOrders: async (configs = {}) => {
      let orderList = await Api.staffGetForetasteOrders(configs);
      return orderList;
    },

    staffGetForetasteOrderById: async (id) => {
      return Api.staffGetForetasteOrderById(id);
    },

    staffUpdateForetasteOrder: async (data) => {
      return Api.staffUpdateForetasteOrder(data);
    },

    staffDeleteForetasteOrder: async (id) => {
      return Api.staffDeleteForetasteOrder(id);
    },

    staffGetContactForms: async (configs = {}) => {
      return Api.staffGetContactForms(configs);
    },
    staffGetContactFormById: async (id) => {
      return Api.staffGetContactFormById(id);
    },
    staffUpdateContactForm: async (data) => {
      return Api.staffUpdateContactForm(data);
    },
    staffDeleteContactForm: async (id) => {
      return Api.staffDeleteContactForm(id);
    },

    staffCreateArticle: async () => {
      return Api.staffCreateArticle();
    },

    staffCreateNews: async () => {
      return Api.staffCreateNews();
    },

    staffUpdateNewsById: async (id, data) => {
      return Api.staffUpdateNewsById(id, data);
    },

    staffGetPreviewArticleUrl: async (id) => {
      return Api.staffGetPreviewArticleUrl(id);
    },

    staffGetArticleEditorUrl: async (id) => {
      return Api.staffGetArticleEditorUrl(id);
    },

    staffDeleteArticle: async (id) => {
      return Api.staffDeleteArticle(id);
    },

    staffUpdateCategory: async (data) => {
      return Api.staffUpdateCategory(data);
    },

    staffTriggerProductCatFileUpdate: async () => {
      return Api.staffTriggerProductCatFileUpdate();
    },

    staffGetMembers: async (configs) => {
      return Api.staffGetMembers(configs);
    },

    staffGetMemberById: async (owner) => {
      return Api.staffGetMemberById(owner);
    },

    staffGetStaffs: async () => {
      return Api.staffGetStaffs();
    },

    staffIssueInvoice: async (orderId) => {
      return Api.staffIssueInvoice(orderId);
    },

    staffVoidInvoice: async (orderId) => {
      return Api.staffVoidInvoice(orderId);
    },

    staffGetNewsList: async (orderId) => {
      return Api.staffGetNewsList(orderId);
    },
    staffResetUserPassword: async (userId) => {
      return Api.staffResetUserPassword(userId);
    },
    staffDeleteUser: async (userId) => {
      return Api.staffDeleteUser(userId);
    },

    staffGenerateInvoice: async (order) => {
      return Api.staffGenerateInvoice(order);
    },

    staffGetStocks: async (query) => {
      return Api.staffGetStocks(query);
    },

    staffCreateUser: async (data) => {
      return Api.staffCreateUser(data);
    },

    staffVerifyPhone: async (data) => {
      return Api.staffVerifyPhone(data);
    },

    staffUpdateOrderToErp: async (data) => {
      return Api.staffUpdateOrderToErp(data);
    },

    ...BulkAppContextActions,
  };

  for (let actKey of Object.keys(_actions)) {
    actions[actKey] = _actions[actKey];
  }

  return (
    <Context.Provider
      value={{
        initialized,
        profile,
        staffUser,
        cart,
        products,
        categoryTree,
        isMenuOpen,
        actions,
      }}>
      {props.children}
      <GlobalSpinner />
      <GlobalModal />
    </Context.Provider>
  );
}

export {Provider, Context, actions as AppActions};
