import React from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';
import * as Icon from './Icon';

const antIcon = (
  <Icon.LoadingOutlined
    size={24}
    color={'#fff'}
    style={{marginRight: 10}}
    spin
  />
);

function Button(props) {
  const {
    size = 'defualt', // default | small
    onClick = () => 0,
    disabled = false,
    shadow = false,
    loading = false,
    type = 'primary', // primary | secondary
    ...otherProps
  } = props;
  return (
    <Wrapper
      className={size !== 'default' ? size : ''}
      shadow={shadow}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...otherProps}>
      {loading && <Spin indicator={antIcon} />}
      {typeof props.children === 'string' ? (
        <span>{props.children}</span>
      ) : (
        props.children
      )}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  /* overwrite defualt button style */
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  --background-color: #b79d7a;
  --hover-background-color: #d8bb94;
  --text-color: #fff;
  --border-color: transparent;

  ${(props) =>
    props.type === 'secondary'
      ? `
  --background-color: #fff;
  --hover-background-color: #e8e8e8;
  --text-color: #595757;
  --border-color: #E5E5EA; 
  `
      : ''}

  &:focus {
    outline: none;
  }

  &:hover {
    background: var(--hover-background-color);
  }

  ${(props) => (props.shadow ? 'box-shadow: 0 6px 14px 4px #F9E9D4;' : '')}
  border-radius: 10px;
  border-radius: 10px;
  width: 270px;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    background-color: #ccbfb0;
  }
  text-align: center;
  font-family: 'Noto Sans TC';

  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  height: 52px;
  border: 2px solid var(--border-color);

  & > span {
    vertical-align: middle;
  }

  &.small {
    font-size: 15px;
    line-height: 22px;
    height: 36px;
    border-radius: 5px;
  }
`;

export default Button;
