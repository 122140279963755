import styled from 'styled-components';
import {
  CloseOutlined as CloseOutlinedIcon,
  LoadingOutlined as LoadingOutlinedIcon,
  GiftOutlined as GiftOutlinedIcon,
  QuestionCircleOutlined as QuestionCircleOutlinedIcon,
  AppstoreOutlined as AppstoreOutlinedIcon,
  CloseSquareOutlined as CloseSquareOutlinedIcon,
  EyeOutlined as EyeOutlinedIcon,
  CopyOutlined as CopyOutlinedIcon,
  ShareAltOutlined as ShareAltOutlinedIcon,
} from '@ant-design/icons';
import * as Feather from 'styled-icons/feather';
import * as MaterialIcon from 'styled-icons/material';

function IconWrapper(IconComp) {
  let Icon = styled(IconComp)`
    color: ${(props) => props.color || '#595757'};
    font-size: ${(props) => props.size || 24}px;
    ${(props) => props.css}
  `;
  return Icon;
}

const CloseOutlined = IconWrapper(CloseOutlinedIcon);
const LoadingOutlined = IconWrapper(LoadingOutlinedIcon);
const GiftOutlined = IconWrapper(GiftOutlinedIcon);
const QuestionCircleOutlined = IconWrapper(QuestionCircleOutlinedIcon);
const AppstoreOutlined = IconWrapper(AppstoreOutlinedIcon);
const CloseSquareOutlined = IconWrapper(CloseSquareOutlinedIcon);
const EyeOutlined = IconWrapper(EyeOutlinedIcon);
const CopyOutlined = IconWrapper(CopyOutlinedIcon);
const ShareAltOutlined = IconWrapper(ShareAltOutlinedIcon);

const KeyboardArrowLeft = IconWrapper(MaterialIcon.KeyboardArrowLeft);
const KeyboardArrowUp = IconWrapper(MaterialIcon.KeyboardArrowUp);
const KeyboardArrowDown = IconWrapper(MaterialIcon.KeyboardArrowDown);
const KeyboardArrowRight = IconWrapper(MaterialIcon.KeyboardArrowRight);
const LocalShipping = IconWrapper(MaterialIcon.LocalShipping);
const AlertCircle = IconWrapper(Feather.AlertCircle);

export {
  CloseOutlined,
  LoadingOutlined,
  GiftOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
  CloseSquareOutlined,
  EyeOutlined,
  CopyOutlined,
  ShareAltOutlined,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardArrowRight,
  LocalShipping,
  AlertCircle,
};
