import URL_PATH from '../UrlPath';

const _normalizePath = (pathname) => {
  // remove trailing slash, except '/'
  return pathname.replace(/(.+)(\/)$/, '$1');
};

function isDashboardPath(pathname) {
  let _isDashboard = false;
  let _pathname = _normalizePath(pathname);
  if (
    _pathname.indexOf('/dashboard') === 0 &&
    _pathname.indexOf(URL_PATH.dashboardLogin) === -1
  ) {
    _isDashboard = true;
  }
  return _isDashboard;
}

function isLoginDashboardPath(pathname) {
  let _isDashboardLogin = false;
  let _pathname = _normalizePath(pathname);
  if (_pathname.indexOf(URL_PATH.dashboardLogin) === 0) {
    _isDashboardLogin = true;
  }
  return _isDashboardLogin;
}

function isBlankLayoutPath(pathname) {
  let _isBlankLayoutPath = false;
  let _pathname = _normalizePath(pathname);
  if (
    _pathname.indexOf(URL_PATH.dashboardLogin) === 0 ||
    _pathname.indexOf(URL_PATH.dashboardArticleEditor) === 0 ||
    _pathname.indexOf(URL_PATH.articlePreview) === 0
  ) {
    _isBlankLayoutPath = true;
  }
  return _isBlankLayoutPath;
}

export {isDashboardPath, isBlankLayoutPath, isLoginDashboardPath};
