const URL_PATH = {
  test: '/test',
  landing: '/',
  dashboardHome: '/dashboard',
  dashboardConfigEc: '/dashboard/config-ec',
  dashboardLogin: '/dashboard/login',
  dashboardProducts: '/dashboard/products',
  dashboardSettings: '/dashboard/settings',
  dashboardOrders: '/dashboard/orders',
  dashboardNews: '/dashboard/news',
  dashboardForetastes: '/dashboard/foretastes',
  dashboardContactUs: '/dashboard/contact-us',
  dashboardCoupon: '/dashboard/coupons',
  dashboardCategories: '/dashboard/categories',
  dashboardMembers: '/dashboard/members',
  dashboardStaffs: '/dashboard/staffs',
  dashboardArticleEditor: '/dashboard/article-editor',
  dashboardStockPreview: '/dashboard/stock-preview',
  articlePreview: '/article', // rev.sdk.js article predefined path
  cart: '/cart',
  // checkout: '/cart/checkout',
  order: '/order',
  orderList: '/order-list',
  product: '/product',
  productPreview: '/product-preview',
  productList: '/products',
  register: '/register',
  profile: '/profile',
  foretaste: '/foretaste',
  about: '/about',
  newsList: '/news-list',
  news: '/news',
  faq: '/faq',

  /* BULK feature */
  dashboardBulkHome: '/dashboard/bulk',
  dashboardBulkOrderList: '/dashboard/bulk/orders',
  bulkProductList: '/bulk/products',
  bulkProduct: '/bulk/product',
};

module.exports = URL_PATH;
