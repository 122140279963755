import * as SdkConfig from 'rev.sdk.js/Config';
import * as Config from '../data.json';

const DATA = {
  clientId: Config.clientId,
  stage: Config.stage,
  apiHost: Config.apiHost,
  authHost: Config.authHost,
  jstoreHost: Config.jstoreHost,
  jstoreVersion: Config.jstoreVersion,
  storageHost: Config.storageHost,
  articleEditorHost: `${Config.consoleHost}/article/editor`,
};

SdkConfig.init(DATA);
