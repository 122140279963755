import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

const ANIMATION_DURATION_MS = 300;

const Modal = () => {
  const [modal, setModal] = useOutlet('modal');
  const visible = !!modal;
  const [innerVisibleContent, setInnerVisibleContent] = React.useState(false);

  React.useEffect(() => {
    if (modal) {
      setInnerVisibleContent(modal);
    } else if (innerVisibleContent && !modal) {
      // modal about to close
      setTimeout(() => setInnerVisibleContent(null), ANIMATION_DURATION_MS);
    }
  }, [modal]);

  return (
    <>
      <Backdrop visible={visible} />
      <Dialog visible={visible} onClick={() => setModal(null)}>
        <div
          className="modal-content-wrapper"
          onClick={(e) => e.stopPropagation()}>
          {innerVisibleContent}
        </div>
      </Dialog>
    </>
  );
};

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: ${ANIMATION_DURATION_MS}ms;
`;

const Backdrop = styled(FullScreenWrapper)`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12;
`;

const Dialog = styled(FullScreenWrapper)`
  transform: ${(props) => (props.visible ? 'scale(1)' : 'scale(0)')};
  transition: ${ANIMATION_DURATION_MS}ms;
  background-color: transparent;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > .modal-content-wrapper {
  }
`;

export default Modal;
