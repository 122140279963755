import {useEffect, useRef} from 'react';

const Gtag = (eventType, event, payload) => {
  try {
    if (typeof window === 'undefined') {
      return;
    }
    window.gtag(eventType, event, payload);
  } catch (e) {
    console.error(e);
  }
};

export const useViewed = (gtagCallBack, negativeCondition) => {
  const isViewed = useRef(false);

  useEffect(() => {
    if (isViewed.current || negativeCondition) {
      return;
    }
    isViewed.current = true;
    gtagCallBack();
  }, [gtagCallBack, negativeCondition]);
};

export default Gtag;
